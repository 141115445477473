import { Component, OnInit } from '@angular/core';
import { Meta, DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent  implements OnInit {
  title = 'Engage-UI';
  constructor(private route: ActivatedRoute,private metaService: Meta){
    this.route.fragment.subscribe(() => {
      this.metaService.updateTag({ name: 'robots', content: 'noindex, nofollow' });
    });
  }
  ngOnInit(): void {
     //Engage Security
     const currentDomain = window.location.hostname;
     // Define the domains where you want noindex, nofollow
     const noIndexDomains = [   'engage-dev.citrincooperman.com',
     'engage-qa.citrincooperman.com',
     'uatengage3.citrincooperman.com',
     'engage.citrincooperman.com'
     ];

     // Check if the current domain matches the noindex, nofollow pattern
     if (noIndexDomains.includes(currentDomain)) {
       // Set the meta tag for robots
       this.metaService.addTag({ name: 'robots', content: 'noindex, nofollow' });
     }
  }

}
